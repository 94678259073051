body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #faf8ef;
  color: #776e65;
  font-size: 18px;
  line-height: 28px;
}
h1 {
  font-size: 36px;
  line-height: 46px;
}
a {
  color: #776e65;
}
a:hover {
  text-decoration: none;
}
.button {
  background: #8f7a66;
  border: 8px solid #8f7a66;
  border-radius: 4px;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: #f9f6f2;
}
